// @flow

import type { Donor as DonorType } from '@archnet/shared';
import { FilterTypes } from '@performant-software/semantic-components';
import i18n from '../i18n/I18n';
import CopyrightRestrictions from '../resources/donations/CopyrightRestrictions.json';
import Donor from '../transforms/Donor';
import Donors from '../services/Donors';
import { type Filter } from '../types/Filter';
import Filters from './Filters';
import WorkStatuses from '../resources/donations/WorkStatuses.json';

const DonationFilters: Array<Filter> = [{
  attributeName: 'name',
  key: 'name',
  label: i18n.t('Donations.filters.name'),
  type: FilterTypes.string
}, {
  attributeName: 'description',
  key: 'description',
  label: i18n.t('Donations.filters.description'),
  type: FilterTypes.text
}, {
  baseClass: 'Donor',
  associationColumn: 'child_id',
  associationName: 'donation_record_associations',
  attributeName: 'donors.id',
  collectionName: 'donors',
  key: 'donor',
  label: i18n.t('Donations.filters.donor'),
  objectName: 'donor',
  onSearch: (search: string) => Donors.fetchAll({ search, sort_by: 'name' }),
  renderOption: (donor) => Donor.toDropdown(donor),
  renderSearchQuery: (donor: DonorType) => donor.name,
  type: FilterTypes.relationship
}, {
  attributeName: 'copyright_restriction',
  key: 'copyright_restriction',
  label: i18n.t('Donations.filters.copyright'),
  options: CopyrightRestrictions,
  type: FilterTypes.select
}, {
  attributeName: 'work_status',
  key: 'work_status',
  label: i18n.t('Donations.filters.workStatus'),
  options: WorkStatuses,
  type: FilterTypes.select
}, {
  attributeName: 'work_status_date',
  key: 'work_status_date',
  label: i18n.t('Donations.filters.workStatusDate'),
  type: FilterTypes.date
}, {
  attributeName: 'negotiated_by',
  key: 'negotiated_by',
  label: i18n.t('Donations.filters.negotiatedBy'),
  type: FilterTypes.string
}, {
  attributeName: 'permission_type',
  key: 'permission_type',
  label: i18n.t('Donations.filters.permissionType'),
  type: FilterTypes.string
}, {
  attributeName: 'date',
  key: 'date',
  label: i18n.t('Donations.filters.date'),
  type: FilterTypes.date
}, ...Filters.excludeFilters('locked', 'published', 'date_published')];

export default DonationFilters;
