// @flow

import type { Donation as DonationType, Translateable } from '@archnet/shared';
import { DatePicker } from '@performant-software/semantic-components';
import type { EditContainerProps } from '@performant-software/shared-components/types';
import React, { type AbstractComponent } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { Form } from 'semantic-ui-react';
import _ from 'underscore';
import AssociatedDonors from '../components/AssociatedDonors';
import AssociatedMedia from '../components/AssociatedMedia';
import AssociatedPublications from '../components/AssociatedPublications';
import CopyrightRestrictions from '../resources/donations/CopyrightRestrictions.json';
import Date from '../utils/Date';
import Donations from '../services/Donations';
import { onMultiAddChildren, onMultiAddParents } from '../utils/RecordAssociation';
import PrimaryImage from '../components/PrimaryImage';
import RecordAssociations from '../services/RecordAssociations';
import RecordHeader from '../components/RecordHeader';
import SimpleEditPage from './SimpleEditPage';
import WorkStatuses from '../resources/donations/WorkStatuses.json';

type Props = Translateable & {
  ...EditContainerProps,
  item: DonationType
};

const Tabs = {
  details: 'details',
  media: 'media',
  publications: 'publications'
};

const Donation: AbstractComponent<any> = withTranslation()((props: Props) => (
  <SimpleEditPage
    {...props}
    className='donation'
  >
    <SimpleEditPage.Header>
      <RecordHeader
        {...props}
        header={props.item.name}
        includePublishButton={false}
        meta={props.item.record_id}
        renderImage={() => (
          <PrimaryImage
            item={props.item}
          />
        )}
      />
    </SimpleEditPage.Header>
    <SimpleEditPage.Tab
      key={Tabs.details}
      name={props.t('Common.tabs.details')}
    >
      <Form.Input
        error={props.isError('name')}
        label={props.t('Donation.labels.name')}
        required={props.isRequired('name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        value={props.item.name || ''}
      />
      <Form.TextArea
        error={props.isError('description')}
        label={props.t('Donation.labels.description')}
        onChange={props.onTextInputChange.bind(this, 'description')}
        required={props.isRequired('description')}
        rows={5}
        value={props.item.description || ''}
      />
      <AssociatedDonors
        items={props.item.donor_record_associations}
        onDataLoaded={(items) => props.onUpdateState({
          donor_record_associations: [
            ...props.item.donor_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'donor_record_associations')}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          child_id: props.item.id,
          child_type: 'Donation',
          parent_type: 'Donor'
        })}
        onSave={onMultiAddParents.bind(this, props, 'donor_record_associations', 'Donor')}
        onSelectPrimary={(item) => props.onSetState({
          donor_record_associations: _.map(
            props.item.donor_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ donor_record_associations: items })}
        resolveDonor={(item) => item.parent}
      />
      <Form.Field>
        <label
          htmlFor='copyright_restriction'
        >
          {props.t('Donation.labels.copyrightRestriction')}
        </label>
        <Form.Group>
          {_.map(CopyrightRestrictions, (cr) => (
            <Form.Radio
              checked={props.item.copyright_restriction === cr.value}
              key={cr.key}
              label={cr.text}
              name='copyright_restriction'
              onChange={props.onTextInputChange.bind(this, 'copyright_restriction')}
              value={cr.value}
            />
          ))}
        </Form.Group>
      </Form.Field>
      <Form.Field>
        <label
          htmlFor='work_status'
        >
          {props.t('Donation.labels.workStatus')}
        </label>
        <Form.Group>
          {_.map(WorkStatuses, (ws) => (
            <Form.Radio
              checked={props.item.work_status === ws.value}
              key={ws.key}
              label={ws.text}
              name='work_status'
              onChange={props.onTextInputChange.bind(this, 'work_status')}
              value={ws.value}
            />
          ))}
        </Form.Group>
      </Form.Field>
      <Form.Input
        error={props.isError('work_status_date')}
        label={props.t('Donation.labels.workStatusDate')}
        required={props.isRequired('work_status_date')}
      >
        <DatePicker
          onChange={(date) => props.onSetState({
            work_status_date: date && date.toDateString()
          })}
          value={Date.withoutTime(props.item.work_status_date)}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('negotiated_by')}
        label={props.t('Donation.labels.negotiatedBy')}
        required={props.isRequired('negotiated_by')}
        onChange={props.onTextInputChange.bind(this, 'negotiated_by')}
        value={props.item.negotiated_by || ''}
      />
      <Form.Input
        error={props.isError('permission_type')}
        label={props.t('Donation.labels.permissionType')}
        required={props.isRequired('permission_type')}
        onChange={props.onTextInputChange.bind(this, 'permission_type')}
        value={props.item.permission_type || ''}
      />
      <Form.Input
        className='date-picker'
        error={props.isError('date')}
        label={props.t('Donation.labels.date')}
        required={props.isRequired('date')}
      >
        <DatePicker
          onChange={(date) => props.onSetState({
            date: date && date.toDateString()
          })}
          value={Date.withoutTime(props.item.date)}
        />
      </Form.Input>
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.media}
      name={props.t('Common.tabs.media')}
      count={props.item.associated_child_media_count}
    >
      <AssociatedMedia
        items={props.item.media_record_associations}
        modal={{
          props: {
            tabs: ['authorities', 'collections', 'sites']
          }
        }}
        onDataLoaded={(items) => props.onUpdateState({
          media_record_associations: [
            ...props.item.media_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'media_record_associations')}
        onEdit={(item, media) => props.onSaveChildAssociation('media_record_associations', {
          ...item,
          child: media
        })}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Donation',
          child_type: 'MediaContent'
        })}
        onSave={(media) => {
          _.each(media, (m) => {
            props.onSaveChildAssociation('media_record_associations', {
              uid: uuid(),
              child_id: m.id,
              child_type: 'MediaContent',
              child: m
            });
          });
        }}
        onSaveMultiple={onMultiAddChildren.bind(this, props, 'media_record_associations', 'MediaContent')}
        onSelectPrimary={(item) => props.onSetState({
          media_record_associations: _.map(
            props.item.media_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ media_record_associations: items })}
        resolveMedia={(item) => item.child}
      />
    </SimpleEditPage.Tab>
    <SimpleEditPage.Tab
      key={Tabs.publications}
      name={props.t('Common.tabs.publications')}
      count={props.item.associated_child_publications_count}
    >
      <AssociatedPublications
        items={props.item.publication_record_associations}
        modal={{
          props: {
            tabs: ['authorities', 'collections', 'sites']
          }
        }}
        onDataLoaded={(items) => props.onUpdateState({
          publication_record_associations: [
            ...props.item.publication_record_associations || [],
            ...items
          ]
        })}
        onDelete={props.onDeleteChildAssociation.bind(this, 'publication_record_associations')}
        onEdit={(item, publication) => props.onSaveChildAssociation('publication_record_associations', {
          ...item,
          child: publication
        })}
        onLoad={(params) => RecordAssociations.fetchAll({
          ...params,
          parent_id: props.item.id,
          parent_type: 'Donation',
          child_type: 'Publication'
        })}
        onSave={(publications) => {
          _.each(publications, (p) => {
            props.onSaveChildAssociation('publication_record_associations', {
              uid: uuid(),
              child_id: p.id,
              child_type: 'Publication',
              child: p
            });
          });
        }}
        onSaveMultiple={onMultiAddChildren.bind(this, props, 'publication_record_associations', 'Publication')}
        onSelectPrimary={(item) => props.onSetState({
          publication_record_associations: _.map(
            props.item.publication_record_associations,
            (i) => ({ ...i, primary: i === item })
          )
        })}
        onUpdate={(items) => props.onSetState({ publication_record_associations: items })}
        resolvePublication={(item) => item.child}
      />
    </SimpleEditPage.Tab>
  </SimpleEditPage>
));

export default {
  component: Donation,
  onInitialize: (id: number): Promise<any> => (
    Donations
      .fetchOne(id)
      .then(({ data }) => data.donation)
  ),
  onSave: (donation: DonationType): Promise<any> => (
    Donations
      .save(donation)
      .then(({ data }) => data.donation)
  )
};
